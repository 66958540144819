import React from 'react';
import logo from './logo.svg';
import { Router, Link, Switch, Route, Redirect } from 'react-router-dom';
import AutoCallbackAliPay2Bank from "./pages/autoCallback/alipay2bank/autoCallback"
import AutoCallbackAliPay2BankV2 from "./pages/autoCallback/alipay2bank/autoCallbackV2"
import relayPage from "./pages/relayPage/relayPage"
import Usdt2erc20 from "./pages/autoCallback/usdt2erc20/usdt2erc20"
import Usdt2trc20 from "./pages/autoCallback/usdt2trc20/usdt2trc20"
import ExpiredAccount from "./pages/expiredAccount/index"
import Bank from "./pages/bank/bank"
import BankV2 from "./pages/bank/bankV2"
import FlashOrder from "./pages/flashOrder/flashOrder"
import Alipay from "./pages/alipay/alipay/alipay"
import VietnamPay from "./pages/vietnamPay/vietnamPay"
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory()

function App() {
  return (
    <Router history={browserHistory}>
      <Switch>
        <Route path="/alipay" exact component={Alipay}></Route>
        <Route path="/alipay2bank" exact component={AutoCallbackAliPay2Bank}></Route>
        <Route path="/uPayAlipay2Bank" exact component={AutoCallbackAliPay2BankV2}></Route>
        <Route path="/digital-payment" exact component={Usdt2erc20}></Route>
        <Route path="/merchantUPay" exact component={Usdt2trc20}></Route>
        <Route path="/relaypage" exact component={relayPage}></Route>
        <Route path="/expiredAccount" exact component={ExpiredAccount}></Route>
        <Route path="/bank" exact component={Bank}></Route>
        <Route path="/uPayBank" exact component={BankV2}></Route>
        <Route path="/flashOrder" exact component={FlashOrder}></Route>
        <Route path="/unionPay" exact component={VietnamPay}></Route>
      </Switch>
    </Router>
  );
}

export default App;

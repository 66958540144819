// import axios from "axios";

export default async option => {
    let req = {
        ...option.data
    }
    let result = "";
    const headers = {}
    // 上传图片，添加upload属性
    if (option.upload) {
        const formData = new FormData();
        for (const field in req) {
            formData.append(field, req[field]);
        }
        result = formData;
    } else {
        headers["content-type"] = "application/x-www-form-urlencoded"
        const arr = [];
        for (const field in req) {
            arr.push(field + "=" + req[field]);
        }
        result = arr.join("&");
    }
    return function p() {
        return new Promise(function (resolve, reject) {
            window.$.ajax({
                dataType: option.dataType || "text",
                type: option.type || "POST",
                url: option.url,
                data: result || "",
                headers,
                processData: false,
                contentType: false,
                success: function (res) {
                    if (typeof res == 'string') {
                        res = JSON.parse(res);
                    }
                    resolve(res);
                },
                error: function (res) {
                    if (typeof res == 'string') {
                        res = JSON.parse(res);
                    }
                    reject(res);
                    if (option.retry) {
                        setTimeout(() => {
                            p();
                        }, 5000)
                    }
                }
            })
        })
    }();
}
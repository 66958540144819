import React from 'react';
import { useState } from 'react';
import "./index.scss"
function ExpiredAccount(props) {
    return (
          <div className="expired-account">
              <p>收款账号已过期</p>
              <p className="second">请选择其他收款账号</p>
        </div>
    );
}
export default ExpiredAccount;

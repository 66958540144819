import React from 'react';
import "./timeOut.scss";
import warning from "../../assets/img/warning.png"
function TimeOut(props) {
    const { obj} = props;
    return (
        <div className="time-out">
            <div className="waring-btn">
                <img  src={warning} alt=""></img>
            </div>
            <div className="time-out-info">
               {
                   obj.country_id == 1?
               <div>
                    订单超时，已自动关闭
                </div>:
                <div className="warning-text">
                    Đơn hàng đã hết thời gian chờ và tự động đóng
                </div>
                 }
            </div>
       </div>
    );
}
export default TimeOut;

import React from 'react';
import "./timeOut.scss";
import warning from "../../../../assets/img/warning.png"
function TimeOut(props) {
    const { orderInfo} = props;
    return (
        <div className="time-out">
            <div className="waring-btn">
                <img  src={warning} alt=""></img>
            </div>
            <div className="time-out-info">
                <div>
                    订单超时，已自动关闭
                </div>
                <p>
                    如果您已付款，请等待金币到账
                </p>
                <p>
                    如果您未付款，请不要继续支付
                </p>
            </div>
       </div>
    );
}
export default TimeOut;

import React from 'react';
import { useState, useEffect, useCallback } from "react";
import "./index.scss";
import Acheader from "../components/alipay/acHeader"
import TabContentBottom from "../components/alipay/tabContentBottom"
import TimeOut from "../components/alipay/timeOut"
import { alipayToBank, completeNotice } from "../../../model/autoCallBack/index"
import { Modal, Toast } from "antd-mobile"
const alert = Modal.alert;
let timer = null;
function AutoCallback(props) {
    const [appayVisible, setApplyVisible] = useState(false);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isCount, setIsCount] = useState(true);
    const [showBigImg, setShowBigImg] = useState(false);
    const [isPay, setIsPay] = useState(false);
    const [showBottomText, setShowBottomText] = useState(true);
    let [orderInfo, setOrderInfo] = useState(() => {
        return {
            account: "123123123",
            amount: "599",
            bank: "",
            bank_sub: "",
            ctime: "",
            exptime: "",
            location: "",
            name: "",
            order_no: "--",
            payment_id: 1,
            qr_code_url: "",
        }
    });

    let [num, setNum] = useState(0);
    let [text, setText] = useState(() => {
        return "-分-秒"
    })

    useEffect(() => {
        document.title = "支付宝支付页"
        let href = window.location.href
        let query = href.substring(href.indexOf('?') + 1);
        let vars = query.split("&");
        let obj = {}
        for (var i = 0; i < vars.length; i++) {
            let pair = vars[i].split("=");
            obj[pair[0]] = pair[1]
        }
        // Toast.loading('Loading...');
        alipayToBank(obj).then(res => {
            // if (res.status === 200) {
            //     Toast.hide()
            //     setLoading(false)
            //     setOrderInfo(res.data)

            //     if (res.data && res.data.order_no) {
            //         const payStr = sessionStorage.getItem(res.data.order_no);
            //         if (payStr == 1) {
            //             setIsPay(true);
            //         }
            //     }
            //     let remain = res.data.time_out;
            //     if (remain > 0) {
            //         setNum(res.data.time_out - 1)
            //     } else {
            //         setNum(0)
            //         setIsCount(false)
            //     }

            //     const timer = setInterval(() => {
            //         setNum(--remain);
            //         let minutes = Math.floor(remain / 60);
            //         let seconds = Math.floor(remain % 60);
            //         let msg = minutes + "分" + seconds + "秒";
            //         setText(msg)
            //         if (remain <= 0) {
            //             setIsCount(false)
            //             clearInterval(timer);
            //             return;
            //         }
            //     }, 1000)
            // }
        })
    }, []);
    const onConfirmDialog = () => {
        setDialogVisible(false);
        setShowBottomText(false);
        let params = {
            order_no: orderInfo.order_no
        }
        completeNotice(params).then(res => {
            if (res.status == 200) {
                sessionStorage.setItem(orderInfo.order_no, '1');
                setIsPay(true);
            }
        })
    }
    const onConfirm = () => {

    }
    return (
        <div className="alipay">
            <Modal
                visible={appayVisible}
                maskClosable={false}
                className="a-modal"
                transparent
                footer={[
                    {
                        text: "知道了，继续支付",
                        onPress: () => {
                            setApplyVisible(false);
                        }
                    }
                ]}
            >
                <div className="content">
                    <div className="content-wrap">
                        将唤起支付宝app，如果无法唤起，请使用 <span>方式2</span> 支付
                    </div>
                </div>
            </Modal>
            {
                showBigImg ?
                    <div className="big-img" onClick={() => {
                        setShowBigImg(false)
                    }}>
                        <img src={orderInfo.qr_url} alt=""></img>
                    </div>
                    : ""
            }
            <Acheader num={num} text={text} value={text} isPay={isPay} orderInfo={orderInfo}></Acheader>
            {
                isCount && !loading ?

                    <TabContentBottom orderInfo={orderInfo}></TabContentBottom> :
                    <React.Fragment>
                        {
                            !loading ?
                                <TimeOut></TimeOut> : ""
                        }
                    </React.Fragment>
            }
            {/* {
                !loading && isCount ?
                    <React.Fragment>
                        {
                            (showBottomText && !isPay) ?
                                <div className="bottom-btn" onClick={() => { setDialogVisible(true) }}
                                >我已付款 {orderInfo.amount} 元</div> :
                                <div className="bottom-btn"
                                >您已确认付款 {orderInfo.amount} 元，请等待金币到账</div>
                        }</React.Fragment> : ""
            } */}
            {/* <Modal
                visible={dialogVisible}
                maskClosable={false}
                className="confirm-modal"
                transparent
                footer={[
                    {
                        text: "取消",
                        onPress: () => {
                            setDialogVisible(false);
                        }
                    },
                    {
                        text: "确认已付款",
                        onPress: () => {
                            onConfirmDialog()
                        }
                    }
                ]}
            >
                <div className="content">
                    <div className="input-wrap">
                        请确认您已付款 <span className="dialog-amount">{orderInfo.amount} </span> 元，若未付款点击“我已付款”将影响您的信用！
                    </div>
                </div>
            </Modal> */}
        </div>
    );
}
export default AutoCallback;

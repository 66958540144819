import React from 'react';
import ReactDOM from "react-dom";
import "./acHeader.scss";
import alipayImg from "../../../../assets/img/alipay.png"
import Clipboard from 'react-clipboard.js'
import { Toast } from "antd-mobile"

function CallbackHeader(props) {
    const { value ,orderInfo,num,text, isPay} = props;
    const onCopySuccess = () => {
        Toast.info("复制成功");
    }
    return (
       <div className="auto-callback-header">
           <div className="ac-header">
               <img className="ac-header-img" src={alipayImg}></img>
               {
                    (num>0&&!isPay)?
                    <div className="ac-header-timer">
                    <p>等待买家付款</p>
                    <p>剩  <span className="timer-count">{text}</span> 自动关闭</p>
                    </div>:""
                }
                {
                    (num>0&&isPay) ?
                    <div className="ac-header-timer">
                    <p>买家已确认付款</p>
                    <p>请等待金币到账</p>
                    </div>:""
                }
           </div>
           <React.Fragment>
                <div className="ac-header-num">充值金额 
                <span className="common-red amount number">¥ {orderInfo.amount}</span> 
                        <span className="common-copy">
                            <Clipboard className="clipboard" onSuccess={onCopySuccess} data-clipboard-text={orderInfo.amount}>
                                        复制
                            </Clipboard>   
                        </span>  
                <p className="common-red amount tips">金额不一致，充值不到账</p> 
                </div> 
                <div className="ac-header-num"> <span className="ac-header-lable">订单编号</span>     <span className="number">{orderInfo.order_no}</span>
                <span className="common-copy">
                <Clipboard className="clipboard" onSuccess={onCopySuccess} data-clipboard-text={orderInfo.order_no}>
                            复制
                    </Clipboard>   
                </span> 
                </div> 
        </React.Fragment>
       </div>
    );
}
export default CallbackHeader;

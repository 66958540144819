import React from 'react';
import "./tabContentThree.scss";
import alipayImg1 from "../../../../assets/img/1.png"
import alipayImg2 from "../../../../assets/img/2.png"
import Clipboard from 'react-clipboard.js'
import { Toast } from "antd-mobile"
function AutoCallbackThree(props) {
    const { orderInfo } = props;
    const onCopySuccess = () => {
        Toast.info("复制成功",1);
    }
    return (
        <div className="ac-content-three">
            
            <div className="a-btn">
                <p> 
                    <label className="label">银行卡卡号</label>
                    <label className="content">{orderInfo.account}</label>  
                    <span className="common-copy">
                    <Clipboard className="clipboard" onSuccess={onCopySuccess} data-clipboard-text={orderInfo.account}>
                           复制
                     </Clipboard>  
                    </span>  
                </p>
                <p> 
                    <label className="label">银行卡姓名</label> 
                    <label className="content">{orderInfo.name}</label> 
                    <span className="common-copy">
                    <Clipboard className="clipboard" onSuccess={onCopySuccess} data-clipboard-text={orderInfo.name}>
                            复制
                    </Clipboard>  
                    </span> 
                </p>
                <p> 
                    <label className="label">开户银行</label> 
                    <label className="content">{orderInfo.bank}</label> 
                    <span className="common-copy">
                    <Clipboard className="clipboard" onSuccess={onCopySuccess} data-clipboard-text={orderInfo.bank}>
                            复制
                    </Clipboard>  
                    </span> 
                </p>
                <p> 
                    <label className="label">开户行支行</label> 
                    <label className="content">{orderInfo.bank_sub}</label> 
                    <span className="common-copy">
                    <Clipboard className="clipboard" onSuccess={onCopySuccess} data-clipboard-text={orderInfo.bank_sub}>
                            复制
                    </Clipboard>  
                    </span> 
                 </p>
            </div>
            <div className="warning-info">
                    <p>
                    ！禁止修改充值金额，否则不到账
                </p>
                <p>
                    ！禁止保存收款信息私自转账
                </p>
                <p>
                    ！禁止重复支付
                </p>
                <p>
                    ！务必在规定时间内付款
                </p>
            </div>
            <div className="tips-info">
                <div  className="info-content">
                     <div className="left-img">
                         <img  src={alipayImg1} alt=""></img>
                    </div>
                    <p> 
                    【复制】姓名和卡号，打开【支付宝】APP
                    </p>
                </div>
                <div className="info-content">
                    <div className="left-img">
                       <img  src={alipayImg2} alt=""></img>
                    </div>
                    <div className="right-text">
                        <p> 
                        点击【转账】-【转到银行卡】，【粘贴】姓名和卡号，点击【下一步】支付即可
                        </p>
                    </div>
                </div>
            </div>    
          
       </div>
    );
}
export default AutoCallbackThree;

import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import "./flashOrder.scss";
import warning from "../../assets/img/warning.png";
import Clipboard from "react-clipboard.js";
import BankForFlash from "../flashPaymentInfo/bankForFlash";
import loadingImg from "../../assets/img/timer.png";
import {
  apiGetPaymentInfo,
  apiGetOrderInfo,
} from "../../model/autoCallBack/index";
import { apiGetUnionOrderInfo } from "../../model/autoCallBack/index";

import { Modal, Toast } from "antd-mobile";
function RelayPage(props) {
  const [dialogVisible, setDialogVisible] = useState(false);
  const [isCount, setIsCount] = useState(false); // 轮训接口是否获取到订单信息
  const [isPay, setIsPay] = useState(false);
  let [num, setNum] = useState('--');
  const [pageLoading, setPageLoading] = useState(true);
  const [isMatch, setIsMatch] = useState(false);
  const [status, setStatus] = useState("--");
  const numRef = useRef();
  let [orderInfo, setOrderInfo] = useState(() => {
    return {
      account_name: "",
      account_no: "",
      amount: "",
      banck_branch: "",
      banck_code: "",
      bank_name: "",
      order_no: "",
      overtime: 0,
      qr_url: "",
      real_amount: "",
    };
  });
  const [detailOrderInfo, setDetailOrderInfo] = useState(() => {
    return {
      account_name: "",
      account_no: "",
      amount: "",
      banck_branch: "",
      banck_code: "",
      bank_name: "",
      order_no: "",
      overtime: 0,
      qr_url: "",
      real_amount: "",
    }
  })
  let [text, setText] = useState(() => {
    return "-分-秒";
  });
  let [urlParams, setUrlParams] = useState(() => {
    return {
      order_no: "",
      sign: "",
    };
  });
  const onCopySuccess = () => {
    Toast.info("复制成功", 1);
  };
  const startTimer = useCallback(time => {
    const param = colectParam();
    apiGetPaymentInfo(param).then((res) => {
      if (res.status === 200) {
        if (res.data.resCode == "success") {
          setStatus(1);
          setIsMatch(true);
          setDetailOrderInfo(res.data.data);
        } else {
          if (numRef.current === 1) {
            setStatus(3);
            return;
          }
          setTimeout(() => {
            startTimer(time);
          }, time);
        }
      }
    });
  }, [num])
  const colectParam = () => {
    let href = window.location.href;
    let query = href.substring(href.indexOf("?") + 1);
    let vars = query.split("&");
    let obj = {};
    for (var i = 0; i < vars.length; i++) {
      let pair = vars[i].split("=");
      obj[pair[0]] = pair[1];
    }
    return obj;
  }
  useEffect(() => {
    if (num == "--" || num==0) {
      return;
    }
    setTimeout(() => {
      numRef.current = num;
      setNum(--num);
    }, 1000)
  }, [num])
  
  useEffect(() => {
    document.title = "闪电支付";
    const param = colectParam();
    setUrlParams(param);
    apiGetOrderInfo(param).then((res) => {
      if (res.status === 200) {
        setStatus(res.data.status);
        setOrderInfo(res.data);
        setNum(res.data.overtime);
        // 0 订单不存在 1待支付 2支付完成 3订单超时 4支付失败
        if (res.data.status == 1) {
          if (res.data.match_rs) {
            setIsMatch(true);
            setDetailOrderInfo(res.data.match_rs);
          } else {
            setIsMatch(false);
            startTimer(res.data.frequency * 1000);
          }
        }
      }
    });
  }, []);
  const btm = () => {
    setDialogVisible(true);
  };
  const getNumStyle = (val) => {
    const numLength = String(val).length;
    if (numLength == 1) {
      return "one-len";
    } else if (numLength == 2) {
      return "two-len";
    } else if (numLength == 3) {
      return "three-len";
    } else {
      return "";
    }
  };
  return (
    <div className="relay-page">
      {
        !(status==1&&isMatch)?
        <div className="tip">
          <div className="title">
            订单号: {urlParams.order_no}
            <span className="common-copy relay-copy">
              <Clipboard
                className="clipboard"
                onSuccess={onCopySuccess}
                data-clipboard-text={urlParams.order_no}
              >
                复制
              </Clipboard>
            </span>
          </div>
          <div className="title">
            金 &nbsp;&nbsp;额:{" "}
            <span className="amout">{orderInfo.amount}</span>
          </div>
        </div>
        :""
      }
      {
        status==0?
        <div className="err-page">
          <div className="waring-btn">
            <img src={warning} alt=""></img>
          </div>
          <p>页面过期</p>
        </div>
        :''
      }
      {
        (status==1&&isMatch)?
        <React.Fragment>
          <BankForFlash orderInfo={detailOrderInfo} setStatus={setStatus}></BankForFlash>
        </React.Fragment>
        :''
      }
      {
        status==1&&!isMatch ?
        <div className="conotent">
          <div className="content-img">
            <img className="loading-img" src={loadingImg} alt=""></img>
            <div className={`count-num ${getNumStyle(num)}`}>{num}</div>
          </div>
          <div className="content-title">订单处理中...</div>
          <div className="msg">大约需要4-6秒，请不要关闭当前页面</div>
          <div className="btn">
            <div className="btn-content"></div>
            <span className="btn-text">请耐心等待....</span>
          </div>
        </div>:''
      }
      
      {
        status==2?
        ''
        :''
      }
      {
        status==3?
        <div className="err-page">
          <div className="waring-btn">
            <img src={warning} alt=""></img>
          </div>
          <p>下单失败</p>
        </div>
        :''
      }
      {
        status==4?
        <div className="err-page">
          <div className="waring-btn">
            <img src={warning} alt=""></img>
          </div>
          <p>已退单</p>
        </div>
        :''
      }
      <Modal
        visible={dialogVisible}
        maskClosable={false}
        className="relay-modal"
        transparent
        footer={[
          {
            text: "关闭",
            onPress: () => {
              setDialogVisible(false);
            },
          },
        ]}
      >
        <div className="content">
          <div className="input-wrap">感谢您的反馈，我们会及时处理</div>
        </div>
      </Modal>
    </div>
  );
}
export default RelayPage;

import React from 'react';
import { useState, useEffect,useCallback } from "react";
import "./tabContentFirst.scss";
import alipayImg1 from "../../../../assets/img/1.png"
import alipayImg2 from "../../../../assets/img/2.png"
import alipayImg3 from "../../../../assets/img/3.png"
import { Modal } from "antd-mobile"
function AutoCallbackFirst(props) {
    const { orderInfo} = props;
    // const [appayVisible, setApplyVisible] = useState(false);
 
    const showDialog = () => {
        setTimeout(() => {
            props.showDialog();
        }, 2000);
    }
    return (
        <div className="ac-content-one">
            <div className="a-btn">
                <a  onClick={showDialog} className="content-one-btn"  href={orderInfo.location}
                >点击支付 {orderInfo.amount} 元</a>
            </div>
            <div className="warning-info">
                    <p>
                    ！禁止修改充值金额，否则不到账
                </p>
                <p>
                    ！禁止保存收款信息私自转账
                </p>
                <p>
                    ！禁止重复支付
                </p>
                <p>
                    ！务必在规定时间内付款
                </p>
            </div>
            <div className="tips-info">
                <div  className="info-content">
                        <div className="left-img">
                            <img  src={alipayImg1} alt=""></img>
                        </div>
                        <div className="right-text">
                            <p> 
                                唤起支付宝APP后，请直接点击下一步进行转卡支付，不要修改任何信息
                            </p>
                        </div>
                </div>
                <div  className="info-content">
                        <div className="left-img">
                            <img  src={alipayImg2} alt=""></img>
                        </div>
                        <div className="right-text">
                            <p> 
                              付款备注请留空，不要填写任何信息
                            </p>
                        </div>
                </div>
                <div  className="info-content">
                        <div className="left-img">
                            <img  src={alipayImg3} alt=""></img>
                        </div>
                        <div className="right-text">
                            <p> 
                            如果唤起失败，请尝试使用方式2支付
                            </p>
                        </div>
                </div>
            </div>    
       </div>
    );
}
export default AutoCallbackFirst;

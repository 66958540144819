import React from 'react';
import ReactDOM from "react-dom";
import { useState, useEffect,useCallback } from "react";
import "./bank-header.scss";
import alipayImg from "../../assets/img/bank.png"
import Clipboard from 'react-clipboard.js'
import { Toast } from "antd-mobile"

function BankHeader(props) {
    const { value ,orderInfo,countNum,text, isPay,onCountOver} = props;
    let [countText, setCountText] = useState(() =>{
        return "-分-秒"
    })
    let [num, setNum] = useState("--");
    useEffect(() => {
        if (orderInfo.order_no) {
            const msg = formatText(orderInfo.pay_over_time);
            setNum(orderInfo.pay_over_time);
            setCountText(msg)
        }
    }, [orderInfo])
    useEffect(() => {
        if (num == '--') {
            console.log("--")
            return;
        }
        setTimeout(() => {
            if (num == 1) {
                onCountOver();
                return;
            }
            setNum(--num);
            const msg = formatText(num);
            setCountText(msg)
        }, 1000)
    }, [num])
    const formatText = val => {
        let minutes = Math.floor(val / 60);
        let seconds = Math.floor(val % 60);
        return minutes + "分" + seconds + "秒";
    }

    const onCopySuccess = () => {
        Toast.info("复制成功");
    }
    return (
       <div className="bank-header">
           <div className="ac-header">
               <img className="ac-header-img" src={alipayImg}></img>
               <div className="ac-header-text">
                    <p className="coin-name">银行卡</p>
              </div>
               {
                    (num>0&&!isPay)?
                    <div className="ac-header-timer">
                    <p>等待买家付款</p>
                    <p>剩  <span className="timer-count">{countText}</span> 自动关闭</p>
                    </div>:""
                }
                {
                    (num>0&&isPay) ?
                    <div className="ac-header-timer">
                    <p>买家已确认付款</p>
                    <p>请等待金币到账</p>
                    </div>:""
                }
           </div>
           <React.Fragment>
                <div className="ac-header-num">充值金额 
                <span className="common-red amount number">¥ {orderInfo.amount}</span> 
                        <span className="common-copy">
                <Clipboard className="clipboard" onSuccess={onCopySuccess} data-clipboard-text={orderInfo.amount}>
                            复制
                    </Clipboard>   
                        </span>  
                </div> 
                <div className="ac-header-num"> <span className="ac-header-lable">订单编号</span>     <span className="number">{orderInfo.order_no}</span>
                <span className="common-copy">
                <Clipboard className="clipboard" onSuccess={onCopySuccess} data-clipboard-text={orderInfo.order_no}>
                            复制
                    </Clipboard>   
                </span> 
                </div> 
        </React.Fragment>
       </div>
    );
}
export default BankHeader;

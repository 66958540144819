import React from 'react';
import { useState, useEffect,useCallback } from "react";
import "./usdt2erc20.scss";
import usdtImg from "../../../assets/img/usdt.png"
import  TimeOut  from "../components/autoCallback/timeOut"
import { apiGetDigitalOrderInfo } from "../../../model/autoCallBack/index"
import Clipboard from 'react-clipboard.js'
import { Toast } from "antd-mobile"
let timer = null;

function Ustd2Erc20(props) {
    const [currTab, setCurrTab] = useState(0);
    const [tabList, setTabList] = useState([]);
    const [currItem, setCurrItem] = useState({});
    const [loading, setLoading] = useState(true);
    const [isCount, setIsCount] = useState(true);
    let [qrcodeUrl, setQrcodeUrl] = useState("");
    let [orderInfo, setOrderInfo] = useState(()=>{
        return {
            amount:"",
            address_list:[
                {
                }
            ]
        }
    });
    
 
    let [text, setText] = useState(() =>{
        return "-分-秒"
    })
    let [num, setNum] = useState(120);
    const onCopySuccess = () => {
        Toast.info("复制成功");
    }
    useEffect(() => { 
        document.title = "USDT支付"
        let href = window.location.href
        let query = href.substring(href.indexOf('?')+1);
        let vars = query.split("&");
        let obj = {}
        for (var i = 0; i < vars.length; i++) {
            let pair = vars[i].split("=");
            obj[pair[0]] = pair[1]
        }
        Toast.loading('Loading...');
        apiGetDigitalOrderInfo(obj).then(res=>{
            if (res.status === 200) {
                Toast.hide()
                setLoading(false)
                setOrderInfo(res.data)
                setNum(res.data.over_time-1)
                setTabList(res.data.address_list);
                if (res.data.address_list.length) {
                    setQrcodeUrl(res.data.address_list[0].qrcode_url);
                    setCurrItem(res.data.address_list[0]);
                }
                let remain = res.data.over_time;
                if (remain>1) {
                    setNum(res.data.time_out-1)
                }else{
                    setNum(0)
                    setIsCount(false)
                }
                const timer = setInterval(() => {
                    setNum(--remain);
                    let  minutes = Math.floor(remain / 60);
                    let  seconds = Math.floor(remain % 60);
                    let  msg = minutes + "分" + seconds + "秒";
                    setText(msg)
                    if (remain === 0) {
                        setIsCount(false)
                        clearInterval(timer);
                        return;
                    }
                }, 1000)
            }
        })
     }, []);
     const onShot = () => {
        let a = document.createElement("a");
        let event = new MouseEvent("click");
        a.download = (Math.random() * 1000000).toFixed();
        a.href = currItem.qrcode_url;
        a.dispatchEvent(event);
    }
    const onChangeTab = useCallback(key => {
        setCurrTab(key);
        setQrcodeUrl(tabList[key].qrcode_url);
        setCurrItem(tabList[key]);
    })
    return (
       <div className="usdt-2-erc20">
            <div className="usdt-2-erc20-header">
                  <div className="ac-header">
                      <img className="ac-header-img" src={usdtImg}></img>
                      <div className="ac-header-text">
                          <p className="coin-name">USDT</p>
                          {
                             orderInfo.address_list.length>1?
                              <p  className="chain-name">{currItem.chain_tag}</p>:""
                          }   
                      </div>
                    {
                        num>0?
                      <div className="ac-header-timer">
                        <p>等待买家付款</p>
                        <p>剩  <span className="timer-count">{text}</span> 自动关闭</p>
                      </div>:""
                    }
                  </div>
                <div className="ac-header-num"> <span className="header-label">充值金额</span>  <span className="number">¥ {orderInfo.amount}</span> 
                </div> 
                <div className="ac-header-num"> <span className="header-label">需支付</span> 
                <span className="common-red amount number">{orderInfo.item_num}</span> 
                    <span className="common-red  number">USDT</span> 
                </div> 
                <div className="ac-header-num"> <span className="header-label">订单编号</span> <span className="number">{orderInfo.order_no}</span>
                    <span className="common-copy">                   
                        <Clipboard className="clipboard" onSuccess={onCopySuccess} data-clipboard-text={orderInfo.order_no}>
                                复制
                        </Clipboard>  
                    </span> 
                </div> 
            </div>
           
            {
                isCount && !loading ?
             <React.Fragment>
              <div className="nav">
                <div className="list-type">
                { tabList.length>1?
                 tabList.map((item,index) => {
                    return (
                        <div
                            key={item.chain_tag}
                            className={'tab ' + (currTab == index ? 'active' : '')}
                            onClick={()=>{onChangeTab(index)}}
                        >
                            <span className="tab-name">{ item.chain_tag }</span>
                            <i className="type-indicator"></i>
                        </div>
                        )
                    }):""
                }
               </div>
            </div>
            <div className="usdt-content">
                    <div className="a-btn">
                        <img className="qr-code-img" src={qrcodeUrl}  alt="" />
                        <div className="content-one-btn" 
                            onClick={onShot}
                        >保存充币二维码</div>
                    </div>
                    <div className="erc20-address">
                    <div className="ac-header-num"> <p className="number">{currItem.address}</p> 
                    <span className="common-copy">
                        <Clipboard className="clipboard" onSuccess={onCopySuccess} data-clipboard-text={currItem.address}>
                                复制
                        </Clipboard>                  
                    </span>  
                </div> 
                    </div>
                    <div className="warning-info">
                            <p>
                            ！请向上述地址转入USDT-{currItem.chain_tag}，禁止转入非USDT-{currItem.chain_tag}，否则无法到账
                        </p>
                        <p>
                            ！禁止保存收款信息私自转账
                        </p>
                        <p>
                            ！禁止重复支付
                        </p>
                        <p>
                            ！请注意您USDT所在平台的提币手续费，请在提币时预留提币手续费
                        </p>
                        <p>
                            ！确保转入准确数量的USDT，否则无法到账
                        </p>
                    </div>
             </div>
             </React.Fragment>
             :
             <React.Fragment>
                 {
                   !loading?
                   <TimeOut></TimeOut>:""
                }
             </React.Fragment>
            }
       </div>
    );
}
export default Ustd2Erc20;

import React from 'react';
import { useState, useEffect, useCallback } from "react";
import "./vietnamPay.scss";
import { Modal, Toast, List, Radio, Flex, WhiteSpace } from "antd-mobile"
import { apiGetPlatformProductList, apiUnionOrder } from "../../model/autoCallBack/index"
import Clipboard from 'react-clipboard.js'
import TimeOut from "./timeOut";
const alert = Modal.alert;
var obj = {};
// var currKey = "0"
function AutoCallback(props) {
    const [appayVisible, setApplyVisible] = useState(false);
    const [appayErrorVisible, setAppayErrorVisible] = useState(false);
    const [timeOut, setTimeOut] = useState(true);
    const [isRequest, setIsRequest] = useState(false);
    const [currKey, setCurrKey] = useState(1);
    const [bankData, setBankData] = useState([]);
    let [num, setNum] = useState(300);
    let [text, setText] = useState(() =>{
        return "-:-"
    })

    const RadioItem = Radio.RadioItem;

    const [urlInfo, setUrlInfo] = useState(() => {
        return {
            agent_payment_id: "",
            callback_url: "",
            app_id: "",
            out_uid: "",
            out_trade_no: "",
            order_no: "",
            amount: "",
            return_type: "",
            success_url: "",
            error_url: "",
            user_id: "",
            encryption: "",
            country_id: "",
        }
    });

    useEffect(() => {
        document.title = "支付页"
        let href = window.location.href
        let query = href.substring(href.indexOf('?') + 1);
        let vars = query.split("&");
        // let obj = {}
        for (var i = 0; i < vars.length; i++) {
            let pair = vars[i].split("=");
            obj[pair[0]] = pair[1]
        }
        setUrlInfo(obj)
        let params = {
            out_trade_no: obj.out_trade_no,
            encryption: obj.encryption,
            amount: obj.amount,
        }
        apiGetPlatformProductList(params).then(res => {
            if (res.status === 200 && res.data.bank_list) {
                Toast.hide()
                startTimer()
                if (JSON.stringify(res.data.bank_list) != "{}") {
                    const tempList = res.data.bank_list;
                    let tempArr = []
                    for (const key in tempList) {
                        tempArr.push(tempList[key])
                    }
                    setBankData(tempArr)

                    let remain = res.data.over_time;
                    if (remain>1) {
                        setNum(res.data.time_out-1)
                    }else{
                        setNum(0)
                        setTimeOut(false)
                    }
                    const timer = setInterval(() => {
                        setNum(--remain);
                        let  minutes = Math.floor(remain / 60);
                        let  seconds = Math.floor(remain % 60);
                        let  msg = ""
                        if (urlInfo.country_id == 1) {
                            msg = minutes + " 分 " + seconds + " 秒";
                        }else{
                            msg = minutes + " Phút " + seconds + " giây";
                        }
                        setText(msg)
                        if (remain === 0) {
                            setTimeOut(false)
                            clearInterval(timer);
                            return;
                        }
                    }, 1000)
                }else{
                    setTimeOut(false)
                }
                setIsRequest(res.data.is_request)
            }else{
                setTimeOut(false)
            }
        })
        const startTimer = ()=>{
            let timer = setTimeout(()=>{
                apiGetPlatformProductList(params).then(res=>{
                    if (res.status === 200 && res.data.bank_list) {
                        Toast.hide()
                        if (JSON.stringify(res.data.bank_list) != "{}") {
                            const tempList = res.data.bank_list;
                            let tempArr = [];
                            for (const key in tempList) {
                                tempArr.push(tempList[key])
                            }
                            setBankData(tempArr);
                        }else{
                            setTimeOut(false)
                        }
                        setIsRequest(res.data.is_request)
                        startTimer()
                    }else{
                        setTimeOut(false)
                        clearInterval(startTimer);
                    }
            })
            },3000)
        }
    }, []);
    const onHandle = (key) => {
        setCurrKey(key);
    }
    const onCopySuccess = () => {
        if (obj.country_id == 1) {
            Toast.info("复制成功");
        }else{
            Toast.info("Sao chép thành công");
        }
    }
    const onConfirm = () => {
        let params = urlInfo;
        params.bank_id = currKey;
        params.order_no = obj.order_no;
        params.encryption = obj.encryption;
        apiUnionOrder(params).then(res => {
            if (res.status == 200) {
                window.open(res.data.pay_url,'_self')
            } else {
                Toast.info(res.msg);
            }
        })
    }
    return (
        <div className="vietnam-pay">
            <div className="top-content">
                <div className="top-count">
                    <div className="top-text">
                        {
                            obj.country_id == 1 ?
                                <React.Fragment>
                                    <p className="top-label"> 订单信息 </p>
                                </React.Fragment> :
                                <React.Fragment>
                                    <p className="top-label"> thông tin đặt hàng </p>
                                </React.Fragment>
                        }
                    </div>
                    {
                        obj.country_id == 1 ?
                        <div className="ac-header">
                                {
                                    (num>0&&timeOut)?
                                    <div className="ac-header-timer">
                                    <p>倒计时</p>
                                    <p>剩  <span className="timer-count">{text}</span> 自动关闭</p>
                                    </div>:""
                                }
                        </div>:
                        <div className="ac-header">
                        {
                            (num>0&&timeOut)?
                            <div className="ac-header-timer">
                            <p>Đếm ngược</p>
                            <p>Trái  <span className="timer-count">{text}</span> tắt</p>
                            </div>:""
                        }
            </div>
                         }
                </div>
                <ul className="tab-wrap">
                    <li
                    >
                        {
                            obj.country_id == 1 ?
                                <React.Fragment>
                                    <span className="label"></span> <span className="value"> {obj.out_trade_no}</span>
                                    <span className="common-copy">
                                        <Clipboard className="clipboard" onSuccess={onCopySuccess} data-clipboard-text={obj.out_trade_no}>
                                            复制
                                        </Clipboard>
                                    </span>
                                </React.Fragment> :
                                <React.Fragment>
                                    <span className="label"> </span> <span className="value"> {obj.out_trade_no}</span>
                                    <span className="common-copy V2">
                                        <Clipboard className="clipboard" onSuccess={onCopySuccess} data-clipboard-text={obj.out_trade_no}>
                                        sao chép
                                        </Clipboard>
                                    </span>
                                </React.Fragment>
                        }
                        <i></i>
                    </li>
                    <li
                    >
                        {
                            obj.country_id == 1 ?
                                <React.Fragment>
                                    <span className="label"> 支付金额 </span> <span className="value red"> {obj.amount} 元</span>
                                    <span className="common-copy">
                                        <Clipboard className="clipboard" onSuccess={onCopySuccess} data-clipboard-text={obj.amount}>
                                            复制
                                        </Clipboard>
                                    </span>
                                </React.Fragment> :
                                <React.Fragment>
                                    <span className="label"> Số tiền thanh toán </span> <span className="value red"> {obj.amount} VND</span>
                                    <span className="common-copy V2">
                                        <Clipboard className="clipboard" onSuccess={onCopySuccess} data-clipboard-text={obj.amount}>
                                        sao chép
                                         </Clipboard>
                                    </span>
                                </React.Fragment>
                        }
                        <i></i>
                        <i></i>
                    </li>

                </ul>
                <div className="line"></div>
                {
                timeOut?
                <React.Fragment>
                 {
                    obj.country_id == 1 ?
                        <React.Fragment>
                            <div className="title-label"> 支付方式 </div>
                        </React.Fragment> :
                        <React.Fragment>
                            <div className="title-label"> phương thức thanh toán </div>
                        </React.Fragment>
                }
                </React.Fragment>:""
            }
            </div>
            {
                timeOut?
            <div className="bottom-content">
                <List>
                    {bankData.map(i => (
                        <RadioItem key={i.bank_id} checked={currKey == i.bank_id} onChange={() => onHandle(i.bank_id)}>
                            {i.show_name}
                        </RadioItem>
                    ))}
                </List>
            </div>:<TimeOut obj={obj} ></TimeOut>
            }
            {
                timeOut?
            <div className="footer-btn">
                {
                    isRequest == 1 ? 
                    <React.Fragment>
                        {
                            obj.country_id == 1 ?
                                <React.Fragment>
                                    <div className="bottom-btn V2"
                                    >确认支付</div>
                                </React.Fragment> :
                                <React.Fragment>
                                    <div className="bottom-btn V2" 
                                    >xác nhận thanh toán</div>
                                </React.Fragment>
                        }
                        </React.Fragment>:
                        <React.Fragment>
                        {
                            obj.country_id == 1 ?
                                <React.Fragment>
                                    <div className="bottom-btn" onClick={() => { onConfirm() }}
                                    >确认支付</div>
                                </React.Fragment> :
                                <React.Fragment>
                                    <div className="bottom-btn" onClick={() => { onConfirm() }}
                                    >xác nhận thanh toán</div>
                                </React.Fragment>
                        } </React.Fragment>}
            </div>:""
            }
            <Modal
                visible={appayVisible}
                maskClosable={false}
                className="b-modal"
                transparent
                footer={[
                    {
                        text: "关闭",
                        onPress: () => {
                            setApplyVisible(false);
                        }
                    }
                ]}
            >
                <div className="content">
                    <div className="content-wrap">
                        下单成功
                    </div>
                </div>
            </Modal>
        </div>
    );
}
export default AutoCallback;

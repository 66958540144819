import React from "react";
import { useState, useEffect, useCallback } from "react";
import "./bank.scss";
import BankHeader from "./bank-header";
import BankBottom from "./bank-bottom";
import TimeOut from "../autoCallback/components/autoCallback/timeOut";
import { alipayToBank, completeNotice } from "../../model/autoCallBack/index";
import { Modal, Toast } from "antd-mobile";
let timer = null;
function Bank(props) {
  const { value, orderInfo, num, text, isPay, count, setStatus } = props;
  const [dialogVisible, setDialogVisible] = useState(false);
  let [countNum, setCountNum] = useState(num);
  const [countText, setCountText] = useState(text);
  const [loading, setLoading] = useState(false);
  let [isCount, setIsCount] = useState(true);
  const [showBottomText, setShowBottomText] = useState(true);

  // useEffect(() => {
  //       const timer = setInterval(() => {
  //           setCountNum(--orderInfo.overtime);
  //           let  minutes = Math.floor(orderInfo.overtime / 60);
  //           let  seconds = Math.floor(orderInfo.overtime % 60);
  //           let  msg = minutes + "分" + seconds + "秒";
  //           setCountText(msg)
  //           if (orderInfo.overtime <= 0) {
  //               setIsCount(false)
  //               clearInterval(timer);
  //               return;
  //           }
  //       }, 1000)
  // }, [])

  // const onConfirmDialog=()=>{
  //     setDialogVisible(false);
  //     setShowBottomText(false);
  //     let params = {
  //         order_no : orderInfo.order_no
  //     }
  //     completeNotice(params).then(res=>{
  //         if (res.status == 200) {
  //             sessionStorage.setItem(orderInfo.order_no, '1');
  //             setIsPay(true);
  //         }
  //     })
  // }
  useEffect(() => {
    if (orderInfo && orderInfo.pay_over_time == 0) {
      setIsCount(false);
    }
  }, [orderInfo])
  const onCountOver = () => {
    setIsCount(false);
  };
  return (
    <div className="bank-pay">
      <BankHeader
        countNum={countNum}
        onCountOver={onCountOver}
        text={text}
        value={text}
        isPay={isPay}
        orderInfo={orderInfo}
      ></BankHeader>
      
      {isCount ? (
        <div className="bottom-content-wrap">
          <BankBottom orderInfo={orderInfo}></BankBottom>
        </div>
      ) : (
        <React.Fragment>
          <TimeOut></TimeOut>
        </React.Fragment>
      )}

      {/* {
            !loading&&isCount?
            <React.Fragment>
           {
               (showBottomText&&!isPay)?
               <div className="bottom-btn" onClick={() => {setDialogVisible(true)}}
               >我已付款 {orderInfo.amount} 元</div>:
               <div className="bottom-btn" 
               >您已确认付款 {orderInfo.amount} 元，请等待金币到账</div>
            }</React.Fragment>:""
            } */}

      {/* <Modal
                visible={dialogVisible}
                maskClosable={false}
                className="confirm-modal"
                transparent
                footer={[
                    {
                        text: "取消",
                        onPress: () => {
                            setDialogVisible(false);
                        }
                    },
                    {
                        text: "确认已付款",
                        onPress: () => {
                            onConfirmDialog()
                        }
                    }
                ]}
            >
                <div className="content">
                    <div className="input-wrap">
                        请确认您已付款 <span className="dialog-amount">{orderInfo.amount} </span> 元，若未付款点击“我已付款”将影响您的信用！
                    </div>
                </div>
            </Modal> */}
    </div>
  );
}
export default Bank;

import api from "../../common/js/api";

const { REACT_APP_API,REACT_APP_FLASH_API,REACT_APP_PAY_API,REACT_APP_T_BANK } = process.env

export function alipayToBank(data = {}) {
    return api({
        url: REACT_APP_API + `/Web/FastPay/alipayToBank`,
        data
    })
}

export function alipayToBankV2(data = {}) {
    return api({
        url: REACT_APP_T_BANK + `/Web/UPay/alipayToBank`,
        data
    })
}

// 支转银成功
export function completeNotice(data = {}) {
    return api({
        url: REACT_APP_API + `/Web/FastPay/completeNotice`,
        data
    })
}

// 数字支付
export function apiGetDigitalOrderInfo(data = {}) {
    return api({
        url: REACT_APP_API + `/Web/Pay/getDigitalOrderInfo`,
        data
    })
}

// 数字支付trc20
export function apiGetDigitalOrderInfoV2(data = {}) {
    return api({
        url: REACT_APP_API + `/Web/MerchantUPay/digitalPay`,
        data
    })
}

// 支付宝跳转
export function apiGetUnionOrderInfo(data = {}) {
    return api({
        url: REACT_APP_API + `/Web/Pay/getUnionOrderInfo`,
        data
    })
}

// 获取云闪电支付信息 
export function apiGetPaymentInfo(data = {}) {
    return api({
        url: REACT_APP_FLASH_API + `/Recharge/PaymentInfo/getPaymentInfo`,
        data
    })
}
// 获取云闪电订单信息
export function apiGetOrderInfo(data = {}) {
    return api({
        url: REACT_APP_FLASH_API + `/Recharge/PaymentInfo/getOrderInfo`,
        data
    })
}
// 聚合越南中间页订单详情
export function apiGetPlatformProductList(data = {}) {
    return api({
        url: REACT_APP_PAY_API + `/Api/UnionPay/getPaymentMiddleOrderInfo`,
        data
    })
}
// 统一下单
export function apiUnionOrder(data = {}) {
    return api({
        url: REACT_APP_PAY_API + `/Api/UnionPay/unionMiddlePageOrder`,
        data
    })
}

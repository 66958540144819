import React from 'react';
import { useState } from 'react';
import "./tabContentSecond.scss";
import alipayImg1 from "../../../../assets/img/1.png"
import alipayImg2 from "../../../../assets/img/2.png"
import alipayImg3 from "../../../../assets/img/3.png"
import alipayImg4 from "../../../../assets/img/4.png"
function AutoCallbackSecond(props) {
   const { orderInfo } = props;
   const [showBigImg,setShowBigImg] = useState(false);
   const onShot = () => {
        let a = document.createElement("a");
        let event = new MouseEvent("click");
        a.download = (Math.random() * 1000000).toFixed();
        a.href = orderInfo.qr_url;
        a.dispatchEvent(event);
    }
   const onImgClick = () => {
      props.showQrCode();
    }
    return (
        <div className="ac-content-second">
            <div>
                <div className="qr-img">
                    <img  src={orderInfo.qr_url} alt="" onClick={onImgClick}>
                    </img>
                    <div className="save-img"  onClick={onShot}>保存二维码</div>
                </div>
                <div className="tips-info">
                    <div  className="info-content">
                        <div className="left-img">
                        <img  src={alipayImg1} alt=""></img>
                        </div>
                        <div className="right-text">
                            <p> 
                            【保存二维码】或【截屏】
                            </p>
                        </div>
                    </div>
                    <div className="info-content">
                        <div className="left-img">
                            <img  src={alipayImg2} alt=""></img>
                        </div>
                        <div className="right-text">
                            <p> 
                            打开【支付宝】APP
                            </p>
                        </div>
                    </div>
                    <div className="info-content">
                        <div className="left-img">
                            <img  src={alipayImg3} alt=""></img>
                        </div>
                        <div className="right-text text3">
                            <p> 
                            点击【扫一扫】，从相册选取保存的二维码图片或截屏
                            </p>
                        </div>
                    </div>
                    <div className="info-content">
                        <div className="left-img">
                            <img  src={alipayImg4} alt=""></img>
                        </div>
                        <div className="right-text">
                            <p> 
                            点击【下一步】支付即可
                            </p>
                        </div>
                    </div>
                </div>   
            </div> 
            <div className="warning-info">
                    <p>
                    ！禁止修改充值金额，否则不到账
                </p>
                <p>
                    ！禁止保存收款信息私自转账
                </p>
                <p>
                    ！禁止重复支付
                </p>
                <p>
                    ！务必在规定时间内付款
                </p>
            </div>
          {/* {

            showBigImg ?
             <div className="big-img" onClick={()=>{
                setShowBigImg(false)
            }}>
                <img src={orderInfo.qr_url} alt=""></img>
            </div>
            :""
        } */}
       </div>
    );
}
export default AutoCallbackSecond;

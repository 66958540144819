import React from 'react';
import { useState, useEffect,useCallback } from "react";
import "./relayPage.scss";
import warning from "../../assets/img/warning.png"
import Clipboard from 'react-clipboard.js'
import loadingImg from "../../assets/img/timer.png"
import { apiGetUnionOrderInfo } from "../../model/autoCallBack/index"
import { Modal,Toast } from "antd-mobile"
function RelayPage(props) {
    const [dialogVisible, setDialogVisible] = useState(false);
    let [num, setNum] = useState(60);
    let [orderInfo, setOrderInfo] = useState(()=>{
        return {
            amount:"",
            address_list:[
                {
                }
            ]
        }
    });
    let [urlParams, setUrlParams] = useState(()=>{
        return {
            order_no:"",
            encryption:""
        }
    });
    const onCopySuccess = () => {
        Toast.info("复制成功",1);
    }
    useEffect(() => { 
        document.title = "优享-聚合支付"
        let href = window.location.href
        let query = href.substring(href.indexOf('?')+1);
        let vars = query.split("&");
        let obj = {}
        for (var i = 0; i < vars.length; i++) {
            let pair = vars[i].split("=");
            obj[pair[0]] = pair[1]
        }
        setUrlParams(obj)
        apiGetUnionOrderInfo(obj).then(res=>{
            if (res.status === 200) {
                setOrderInfo(res.data)
                if(res.data.pay_url !==""){
                   window.open(res.data.pay_url,'_self')
                   return
                }
                let remain = Number(res.data.over_time);
                startTimer()
                if (remain>2) {
                    setNum(remain-1)
                }else{
                    setNum(0)
                }
                const timer = setInterval(() => {
                    setNum(--remain);
                    if (remain <= 0) {
                        clearInterval(timer);
                        return;
                    }
                }, 1000)
            }
        })
        const startTimer = ()=>{
            let timer = setTimeout(()=>{
              apiGetUnionOrderInfo(obj).then(res=>{
                if (res.status === 200 && res.data.pay_url !=="") {
                    window.open(res.data.pay_url,'_self')
                    clearInterval(startTimer);
                }else{
                    startTimer()
                }
            })
            },3000)
        }
     }, []);
     const btm = ()=>{
        setDialogVisible(true)
     }

     const getNumStyle = (val)=>{
         const numLength = String(val).length;
         if(numLength==1){
             return "one-len"
         }else if(numLength ==2){
             return "two-len"
         }else if(numLength ==3){
             return "three-len"
         }else{
             return ""
         }
     }
    return (
          <div className="relay-page">
                    <div className="top">
                    </div>
                    <div className="tip">
                        <div className="title">订单号: {urlParams.order_no}   
                            <span className="common-copy relay-copy">
                                <Clipboard className="clipboard" onSuccess={onCopySuccess} data-clipboard-text={urlParams.order_no}>
                                        复制
                                </Clipboard>  
                            </span> 
                        </div>
                    </div>
                 {
                 num>0?
                 <React.Fragment>
                 <div className="conotent">
                        <div className="content-img">
                                <img className="loading-img" src={loadingImg} alt="">
                                </img>
                                <div className={`count-num ${getNumStyle(num)}`}>{num}</div>
                        </div>
                        <div className="content-title">订单处理中...</div>
                        <div className="msg" >大约需要4-6秒，请不要关闭当前页面</div>
                        <div className="btn" >
                            <div className="btn-content" > 
                            </div>
                            <span className="btn-text">
                                请耐心等待....
                            </span>
                        </div>
                    </div>
            </React.Fragment>:
            <React.Fragment>
                <div className="err-page">
                    <div className="waring-btn">
                        <img  src={warning} alt=""></img>
                    </div>
                    <p>
                        订单处理失败，请重新发起支付
                    </p>
                </div>
            </React.Fragment>
          }
            <Modal
                visible={dialogVisible}
                maskClosable={false}
                className="relay-modal"
                transparent
                footer={[
                    {
                        text: "关闭",
                        onPress: () => {
                            setDialogVisible(false);
                        }
                    }
                ]}
            >
                <div className="content">
                    <div className="input-wrap">
                        感谢您的反馈，我们会及时处理
                    </div>
                </div>
            </Modal>
            {
                 num>0? <div className="btm" onClick={btm}>无法支付？点击这里</div>:""
            }
       </div>
    );
}
export default RelayPage;
